import "./donate-lightbox.css"

import React, {
    useCallback,
    useEffect,
    useState,
} from "react"

import axios from "axios"
import { navigate } from "gatsby"
import numeral from "numeral"
import { useForm } from "react-hook-form"
import ReactInputMask from "react-input-mask"
import { CSSTransition } from "react-transition-group"

import { XIcon } from "@heroicons/react/solid"

import { API_BASE_URL } from "../../constants/config"
import {
    defaultMonths,
    emailFormat,
    phoneFormat,
    phoneFormatField,
    zipFormat,
    zipFormatField,
} from "../../constants/defaults"
import Alert from "./Alert"
import CurrencyInput from 'react-currency-input-field';

const DonateLightbox = ({ show, donation_type, donation_item, donation_items, onClose }) => {

    const [states, setStates] = useState(null);
    const [amount, setAmount] = useState(0);
    const [amountError, setAmountError] = useState({ show: false, message: "" })
    const [donationTypes, setDonationTypes] = useState(null);
    const [donationType, setDonationType] = useState(null);
    const [formState, setFormState] = useState({ showForm: false, busy: false, formResult: null });
    const { register, handleSubmit, formState: { errors }, setValue, unregister, watch } = useForm();
    const txt_notes = watch("notes", "");

    const onSubmit = data => {
        if (!formState.busy) {
            if (['one-time', 'memorial-gifting'].indexOf(donationType) > -1) {
                if (parseFloat(amount) < 5) {
                    setAmountError({ show: true, message: "Website donation minimum is $5" })
                    document.querySelector(".donate-form-box").scroll({ top: 0, behavior: 'smooth' })
                    return;
                }

                if (parseFloat(amount) != 27.5) {
                    if ((parseFloat(amount) % 1) != 0) {
                        setAmountError({ show: true, message: "Amount should be a whole number" })
                        document.querySelector(".donate-form-box").scroll({ top: 0, behavior: 'smooth' })
                        return;
                    }
                }
            }

            data = { ...data, amount: parseFloat(amount), donation_type: donationType };
            setFormState((state) => ({ ...state, busy: true }))
            axios.post(API_BASE_URL + "/website/donate/submit", data).then(({ data }) => {
                setFormState((state) => ({ ...state, busy: false, formResult: { text: data.message, error: false } }))
                document.querySelector(".donate-form-box").scroll({ top: 0, behavior: 'smooth' })
                document.querySelector("body").className = '';
                navigate("/thank-you-for-your-donation/");
            }).catch(({ response }) => {
                setFormState((state) => ({ ...state, busy: false }))
                if (response) {
                    setFormState((state) => ({ ...state, formResult: { text: response.data.message, error: true } }))
                    document.querySelector(".donate-form-box").scroll({ top: 0, behavior: 'smooth' })
                }
            });

        }

    }
    const setSelectedAmount = useCallback((item) => {
        if (donation_items) {
            for (var i = 0; i < donation_items.length; i++) {
                if (donation_items[i]._id === item) {
                    if (donation_items[i].amount && donation_items[i].amount > 0) {
                        setAmount(donation_items[i].amount);
                    }
                }
            }
        }
    }, [donation_items]);
    useEffect(() => {
        var m = true;
        if (m) {
            setFormState((state) => ({ ...state, busy: false }))
            setDonationType(donation_type);
            setValue("donation_item", donation_item);
            if (donation_item !== "") {
                setSelectedAmount(donation_item);
            }

        }
        return () => {
            m = false;
        }
    }, [donation_type, donation_item, setValue, setSelectedAmount])
    useEffect(() => {
        if (['one-time', 'memorial-gifting'].indexOf(donationType) === -1) {
            unregister("amount")
        }
        if (donationType === 'one-time') {
            unregister("donation_item")
        }
    }, [donationType, unregister]);


    useEffect(() => {
        var m = true;
        if (m) {
            setValue("notes", "");
        }
        return () => {
            m = false;
        }
    }, [])


    useEffect(() => {
        var m = true;
        if (m) {
            axios.get(API_BASE_URL + "/static/states").then(({ data }) => {
                setStates(data.data);
            })
            axios.get(API_BASE_URL + "/website/donate/donation-types").then(({ data }) => {
                setDonationTypes(data.data);
            })

            setTimeout(() => {
                setValue("state", "TX");
            }, 1000)
        }
        return () => {
            m = false;
        }
    }, [])

    const renderYears = () => {
        var yearFrom = (new Date()).getFullYear();
        var years = [];
        for (var m = yearFrom; m <= yearFrom + 20; m++) {
            years.push(m);
        }
        return years.map((year, key) => {
            return <option key={key} value={year}>{year}</option>
        })
    }

    const selectDonationType = (type) => {
        setDonationType(type);

        setValue("donation_item", "");
        setValue("amount", "");
        setAmount(0);

    }

    const selectedDonationType = (type) => {
        if (donationTypes) {
            for (var i = 0; i < donationTypes.length; i++) {

                if (donationTypes[i].key === type) {
                    return donationTypes[i].name;
                }
            }
        }
        return ""
    }



    return <CSSTransition in={show} timeout={500} classNames="fade" unmountOnExit onEntered={() => { setFormState((state) => ({ ...state, showForm: true })) }}><div className="donate-form-box">
        <CSSTransition in={formState.showForm} timeout={500} classNames="zoomIn" unmountOnExit onExited={() => { onClose() }}>
            <div className="donate-form">
                <h3>Donate</h3>
                <button onClick={() => { setFormState((state) => ({ ...state, showForm: false })) }} aria-label="close" className="btn-close"><XIcon className="w-8 h-8 text-orange-light" /></button>
                <Alert data={formState.formResult} />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='form-fields'>
                        <div className='field-group col-span-2'>
                            <label htmlFor='donation'>Donation <span className='req'>*</span></label>
                            <ul className='donation-type'>
                                {donationTypes && donationTypes.filter(item => {
                                    return item.key !== 'adoption-application'
                                }).map((item, key) => {
                                    return <li key={key}><button onClick={() => { selectDonationType(item.key) }} className={donationType === item.key ? 'active' : ''} type='button'>{item.name}</button></li>
                                })}
                            </ul>
                        </div>
                        {(donationType !== 'one-time' && donationType !== '') && <div className='field-group col-span-2'>
                            <label htmlFor='donation_item'>{selectedDonationType(donationType)} <span className='req'>*</span></label>
                            <select {...register('donation_item', { required: true })}
                                onChange={(e) => {
                                    var { value } = e.target
                                    setSelectedAmount(value);

                                }}
                                id="donation_item">
                                <option value="">Select</option>
                                {donation_items && donation_items.map((item, key) => {
                                    return item.donation_type.key === donationType && <option key={key} value={item._id}>
                                        {parseFloat(item.amount) > 0 ? "$" + item.amount : ""}{" " + (item.name ? item.name : "")}
                                    </option>
                                })}
                            </select>
                            {errors.donation_item && <span className='text-error'>Please select an option</span>}
                        </div>}
                        {['one-time', 'memorial-gifting'].indexOf(donationType) > -1 && <div className='field-group col-span-2'>
                            <label htmlFor='amount'>Amount <span className='req'>*</span></label>
                            {/* <input {...register('amount', { required: true, min: 5 })} onKeyPress={e => {
                                if (isNaN(e.key) && e.key !== '.') {
                                    e.preventDefault()
                                }
                            }} onChange={(e) => { setAmount(e.target.value) }} type="text" id="amount" /> */}


                            <CurrencyInput  {...register('amount', { required: true, min: 5 })} onValueChange={value => {
                                setAmount(value)
                                setAmountError({ show: false })
                            }} value={amount ? amount : null} allowNegativeValue={false} decimalSeparator="." disableGroupSeparators={true} groupSeparator="" prefix="" className="text-input-inner" />
                            {errors.amount && errors['amount'].type === 'required' && <span className='text-error'>Amount is required</span>}
                            {errors.amount && errors['amount'].type === 'min' && <span className='text-error'>Website donation minimum is $5</span>}
                            {amountError.show && <span className="text-error">{amountError.message}</span>}
                        </div>}
                        <div className='field-group col-span-2'>
                            <label htmlFor='notes'>Notes </label>
                            <textarea maxlength="200" {...register('notes', { required: true })} id="notes"></textarea>
                            {errors.notes && errors['notes'].type === 'required' && <span className='text-error'>Notes is required</span>}
                            <span className="text-gray-500 text-sm">{(200 - (txt_notes ? String(txt_notes).length : 0))} remaining character(s)</span>
                        </div>
                        <div className='field-group col-span-2 md:col-span-1'>
                            <label htmlFor='first_name'>First Name <span className='req'>*</span></label>
                            <input {...register('first_name', { required: true })} type="text" id="first_name" />
                            {errors.first_name && <span className='text-error'>First Name is required</span>}
                        </div>
                        <div className='field-group col-span-2 md:col-span-1'>
                            <label htmlFor='last_name'>Last Name <span className='req'>*</span></label>
                            <input {...register('last_name', { required: true })} type="text" id="last_name" />
                            {errors.last_name && <span className='text-error'>Last Name is required</span>}

                        </div>
                        <div className='field-group col-span-2'>
                            <label htmlFor='street'>Street Address <span className='req'>*</span></label>
                            <input {...register('street', { required: true })} type="text" id="street" />
                            {errors.street && <span className='text-error'>Street Address is required</span>}
                        </div>
                        <div className='field-group col-span-2 md:col-span-1'>
                            <label htmlFor='city'>City <span className='req'>*</span></label>
                            <input {...register('city', { required: true })} type="text" id='city' />
                            {errors.city && <span className='text-error'>City is required</span>}
                        </div>
                        <div className='field-group col-span-2 md:col-span-1'>
                            <label htmlFor='state'>State <span className='req'>*</span></label>
                            <select {...register('state', { required: true })} id="state">
                                <option value="">Select</option>
                                {states && states.map((item, key) => {
                                    return <option key={key} value={item.key}>{item.name}</option>
                                })}
                            </select>
                            {errors.state && <span className='text-error'>State is required</span>}
                        </div>
                        <div className='field-group col-span-2 md:col-span-1'>
                            <label htmlFor='zipcode'>Zip / Postal Code <span className='req'>*</span></label>
                            <ReactInputMask type={"text"} {...register('zipcode', { required: true, pattern: zipFormat })} mask={zipFormatField} />
                            {errors.zipcode && errors.zipcode.type === 'required' && <span className='text-error'>Zip/Postal Code is required</span>}
                            {errors.zipcode && errors.zipcode.type === 'pattern' && <span className='text-error'>Zip/Postal Code is invalid format</span>}
                        </div>

                    </div>
                    <div className='form-fields mt-4 mb-5'>
                        <div className='field-group col-span-2 md:col-span-1'>
                            <label htmlFor="email">Email <span className='req'>*</span></label>
                            <input {...register('email', { required: true, pattern: emailFormat })} type="text" id="email" />
                            {errors.email && errors.email.type === 'required' && <span className='text-error'>Email is required</span>}
                            {errors.email && errors.email.type === 'pattern' && <span className='text-error'>Email format is invalid</span>}
                        </div>
                        <div className='field-group col-span-2 md:col-span-1'>
                            <label htmlFor='phone'>Phone</label>
                            <ReactInputMask type={"text"} {...register("phone", { required: false, pattern: phoneFormat })} mask={phoneFormatField} />

                        </div>
                    </div>
                    <h4>Credit Card <span className='req'>*</span></h4>
                    <span className='credit-cards'></span>
                    <div className='form-fields mt-4 mb-5'>
                        <div className='field-group col-span-2'>
                            <label htmlFor='card_holder_name'>Card Holder Name <span className='req'>*</span></label>
                            <input {...register('card_holder_name', { required: true })} type="text" id="card_holder_name" />
                            {errors.card_holder_name && <span className='text-error'>Card Holder Name is required</span>}
                        </div>
                        <div className='field-group col-span-2'>
                            <label htmlFor='card_number'>Card Number <span className='req'>*</span></label>
                            <input {...register('card_number', { required: true })} type="text" id="card_number" />
                            {errors.card_number && <span className='text-error'>Card Number is required</span>}
                        </div>
                    </div>
                    <div className='grid gap-4 grid-cols-3 mb-4'>
                        <div className='field-group col-span-3 md:col-span-1'>
                            <label htmlFor='month'>Month <span className='req'>*</span></label>
                            <select {...register('month', { required: true })} id='month'>
                                <option value="">Select</option>
                                {defaultMonths && defaultMonths.map((item, key) => {
                                    return <option key={key} value={item.value}>{item.label}</option>
                                })}
                            </select>
                            {errors.month && <span className='text-error'>Month is required</span>}
                        </div>
                        <div className='field-group col-span-3 md:col-span-1'>
                            <label htmlFor='year'>Year <span className='req'>*</span></label>
                            <select {...register('year', { required: true })} id="year">
                                <option value="">Select</option>
                                {renderYears()}
                            </select>
                            {errors.year && <span className='text-error'>Year is required</span>}
                        </div>
                        <div className='field-group col-span-3 md:col-span-1'>
                            <label htmlFor='security_code'>Security Code <span className='req'>*</span></label>
                            <input {...register('security_code', { required: true })} type="text" id="security_code" />
                            {errors.security_code && <span className='text-error'>Security Code is required</span>}
                        </div>
                    </div>
                    <h4>Donation Total</h4>
                    <span className='donate_total'>${numeral(amount).format('0,0.00')}</span>
                    <button type="submit" className={"btn-primary " + (formState.busy ? 'disabled' : '')}>{formState.busy ? "Donating..." : "Donate"}</button>
                </form>
            </div>

        </CSSTransition>
    </div>
    </CSSTransition>
}

export default DonateLightbox